body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



        /* Navigation bar styles */
        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #78c9a8; /* Light green background */
          padding: 10px;
      }

      .navbar a {
          font-size: 16px;
          color: #333; /* Dark gray text color */
          text-align: center;
          text-decoration: none;
          padding: 10px 15px;
      }

      .navbar .menu a:hover {
          background-color: #5faa88; /* Slightly darker green background on hover */
    color:white;
      }

      .logo img {
          width: 40px;
          height: 40px;
      }

#logo{
  
    width: 5vw;
    height: 5vw;
    background: white;
    border-radius: 50%;

}
.carousel-inner img {
           height: 67vh;
  width: 100vw;
  object-fit: cover;
      }

.container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
      }

      .box {
          flex-grow: 1;
          border: 1px solid #ccc;
          padding: 20px;
          text-align: center;
      }

      .box img {
          max-width: 100%;
          height: auto;
      }

      /* Adjust widths */
      .middle {
          flex-grow: 4; /* 80% width for middle box */
  padding: 20px;
          text-align: center;
      }

      .box.first,
      .box.last {
          flex-grow: 1; /* 20% width for first and last boxes */
      }
#header{
display: flex;
  justify-content: center;
  background: gray;
  height: 11vh;
  }

  #header img{
    width:50%;
  }

 footer {
          background-color: #333; /* Dark background color */
          color: #fff; /* Text color */
          text-align: center;
          padding: 10px 0;
      }

 /* Dropdown styles */
      .menu {
          display: flex;
          flex-wrap: wrap;
      }

      .menu a {
          text-decoration: none;
          padding: 10px 15px;
          color: #333;
          margin-right: 10px;
          margin-bottom: 10px;
          transition: background-color 0.3s, color 0.3s;
      }

      .menu a:hover {
          background-color: #5faa88; /* Slightly darker green background on hover */
          color: #fff; /* White text color on hover */
      }


      .dropbtn {
        background: transparent;
        color: #333;
        padding: 14px;
        font-size: 16px;
        border: none;
      }

      .Hammenu{
        background: transparent;
        color: #333;
        padding: 14px;
        font-size: 16px;
        border: none;
      }
      
      .dropdown {
        position: relative;
        display: inline-block;
      }
      
      .dropdown-content {
        display: none;
        border-radius: 0px 15%;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      }
      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      
      .dropdown-content a:hover {background-color: #ddd; }
      
      .dropdown:hover .dropdown-content {display: block;}
      
      .dropdown:hover .dropbtn {background-color: #5faa88; cursor: pointer;}

.flex-container {
          display: flex;
   background: linear-gradient(to bottom, #78c9a8, #ccc);
          justify-content: space-between;
          padding: 20px;
    
      }

      .flex-item {
              flex-grow: 1;
  border: 1px solid #ccc;
  padding: 4px 77px;
  text-align: center;
      }
.flex-item p{
  text-align:left ;
  }
.flex-item a:visited{
      color: white;
  }
.flex-item a:hover{
  color:green;
  text-decoration:underline; 
  }




#map {
  width: 100%;
  height: 400px; /* Adjust the height as needed */
}



.imgset{
   width:100vw;
   height:50vh;
}
  
.centered-slider {
    max-width: 60vw; /* Adjust the maximum width as needed */
    margin: 0 .5vw; /* Center the slider horizontally */
    z-index: -1;
    
  }

  .Homesec{
    display: flex;
  }


  .yousec ,.CelebSec{
    border: 2px solid gray;
   max-width: 20vw;
   max-height: 50vh;
   overflow-y: scroll;
   overflow-x: hidden;
    
  }

  .celebimg img{
    width:20vw;
    height:30vh;
  }
  .CelebSec{
    overflow: hidden;
  }
  .yousec::-webkit-scrollbar{
    display: none;
  }

 /* VerticalAutoScrollText.css */

.vertical-auto-scroll-text {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
    position: relative;
    background: linear-gradient(
    to bottom,
    #ff993379 0%,   /* Saffron */
    #ff993379 0%,  /* Saffron */
    white 33%,     /* White */
    #1388084f 66%,  /* Green */
    #1388084f 66%  /* Green */
  );
  background-blend-mode: lighten;
  }
  
  .scrolling-content {
    animation: scrollText 50s linear infinite; /* Adjust the duration as needed */
  }
  
  .scrolling-content p {
    margin: 0;
    padding: 10px;
  }
  
  @keyframes scrollText {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
.video iframe{
    width: 20vw;
    height: 8vw;
}
  
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

/* Style each image container */
.image {
    flex: 0 0 calc(33.33% - 20px); /* 3 columns with 20px spacing between them */
    margin-bottom: 20px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s; /* Add transition effect */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

/* Style the images */
.image img {
    max-width: 100%;
    height: auto;
    display: block;
}

/* Hover effect */
.image:hover {
	cursor:pointer;
    transform: scale(1.05); /* Scale up by 5% on hover */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Apply a stronger box shadow on hover */
}
/* src/components/Contact.css */

.contact-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.contact-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-page form {
  display: flex;
  flex-direction: column;
}

.contact-page label {
  font-weight: bold;
  margin-bottom: 5px;
}

.contact-page input,
.contact-page textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.contact-page button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.contact-page button:hover {
  background-color: #0056b3;
}




/* styles.css */

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is on top */
}

.loader {
  border: 5px solid #f3f3f3; /* Light gray border */
  border-top: 5px solid #3498db; /* Blue border */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Rotate animation */
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Responsive Hamburger */
.hamburger-menu {
  display: none; /* Initially hide the menu */
}
.menu-item{
  display: none;
  position: absolute;
  right: 0;


}

.responsive-menu{
  border: 2px solid gray;
  border-radius: 27px 0 0 25px;
  transition: right 05s ease-out;
}
.responsive-menu div,a{
  color: #333;
  text-decoration: none;
  
}

.open {
  display: none; /* Show the menu when open */
}

.hamburger-icon {
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
}

/* Media query for small screens (e.g., mobile) */
@media screen and (max-width: 975px) {
  .hamburger-menu {
    display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #78c9a8; /* Light green background */
          padding: 10px;
  }
  .navbar{
    display: none;
  }

  .open {
    display: block; /* Show the menu when open */
  }

  .imgset
{
height: auto;
}
img#sideimg{
 width: 100%;
}

.dropdown-content{
  
  position: unset;
 
}
  .flex-container{
    display: block;
  }

  .Homesec{
    display: block;
  }

  .centered-slider{
    max-width: unset;
  }
  .CelebSec{
    
    max-width: unset;
    width: 100%;
    position: sticky;
    z-index: -1;
  }

  .celebimg img{
    width: 50vw;
    height: 30vh;
  }
  .vertical-auto-scroll-text{
    height: 30vh;
  }
  .yousec{
    display: flex;
    max-width: unset;
    width: 92%;
    padding: 10px;
    margin-top: 7vw;
    overflow-x: scroll;
  }

.video iframe{
    width: 50vw;
    height: 50vw;
    padding: 20px;
    
  }
 
  
  .centered-slider{
    position:sticky;
  }

  #logo{
    width: 10vw;
    height: 10vw;
  }
 

}

@media screen and (max-width: 460px) {
.container{
  display: block;
}
.loaderWrapper{
  display: none;
}
}

#sideimg{
  width: 60vw;
}

.centered-box {
   align-items: center;
  min-height: 100vh;
  background-color: whitesmoke;
  margin: 2vw 18vw;
  border: 0.4vw solid gray;
  
}
.insidecentered{
  display: flex;
  margin-top: 1vw;
  justify-content: space-around;
}


/* ComplaintForm.css */

.complaint-form-container {
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.complaint-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 20px;
  margin-left: 7vw;
}

.image-preview img {
  width: 26vw;
  max-width: 100%;
  height: auto;
}

.No_data{
  text-align: center;
  font-weight: bold;
  font-size: 2vw;
}